import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ETHOptionsResponse, OrderbookResponse } from './types';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
// Styled components
const AppContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  color: #333;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const SelectedInstrument = styled.div`
  background-color: #e0e0e0;
  padding: 15px;
  border-radius: 5px;
  flex: 1;
`;

const Header = styled.header`
  background-color: #333;
  color: #fff;
  padding: 5px;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
`;
const AmountInput = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
`;

// Utility functions
const getStrikePriceColor = (strikePrice: number, ethPrice: number | null, selectedToken: string) => {
  if (!ethPrice) return 'black';

  if (selectedToken === 'ETH') {
    return strikePrice < ethPrice ? 'red' : 'green';
  } else {
    return strikePrice > ethPrice ? 'red' : 'green';
  }
};

const calculateAprRange = (baseApr: number, leverage: number, slippage: number) => {
  const leveragedBaseApr = baseApr * leverage;
  const minApr = Math.max(0, leveragedBaseApr * (1 - slippage / 100));
  return `${minApr.toFixed(2)}% - ${leveragedBaseApr.toFixed(2)}%`;
};

// Sub-components
interface TokenSelectorProps {
  selectedToken: string;
  setSelectedToken: (token: string) => void;
  options: ETHOptionsResponse | null;
}

const TokenSelector: React.FC<TokenSelectorProps> = ({ selectedToken, setSelectedToken, options }) => {
  return (
    <Section>
      <SectionTitle>Step 1: Select token</SectionTitle>
      <Select
        value={selectedToken}
        onChange={e => {
          const newToken = e.target.value;
          setSelectedToken(newToken);
          // Additional logic for updating other state values
        }}
      >
        <option value="ETH">ETH</option>
        <option value="USDC">USDC</option>
      </Select>
    </Section>
  );
};
interface MaturitySelectorProps {
  selectedMaturity: string;
  setSelectedMaturity: (maturity: string) => void;
  maturities: string[];
}

const MaturitySelector: React.FC<MaturitySelectorProps> = ({ selectedMaturity, setSelectedMaturity, maturities }) => {
  return (
    <Section>
      <SectionTitle>Step 2: Select Expiry Date</SectionTitle>
      <Select
        value={selectedMaturity}
        onChange={e => {
          const newMaturity = e.target.value;
          setSelectedMaturity(newMaturity);
          // Additional logic for updating strike price
        }}
      >
        {maturities.map(maturity => (
          <option key={maturity} value={maturity}>{maturity}</option>
        ))}
      </Select>
    </Section>
  );
};
interface StrikePriceSelectorProps {
  selectedStrikePrice: number;
  setSelectedStrikePrice: (price: number) => void;
  strikePrices: number[];
  ethPrice: number | null;
  selectedToken: string;
}

const StrikePriceSelector: React.FC<StrikePriceSelectorProps> = ({
  selectedStrikePrice,
  setSelectedStrikePrice,
  strikePrices,
  ethPrice,
  selectedToken
}) => {
  return (
    <Section>
      <SectionTitle>Step 3: Select price to {selectedToken === 'ETH' ? 'sell' : 'buy'} ETH at</SectionTitle>
      <div>Current ETH Price: ${ethPrice}</div>
      <Select
        value={selectedStrikePrice}
        onChange={e => setSelectedStrikePrice(Number(e.target.value))}
      >
        {strikePrices.map(price => (
          <option
            key={price}
            value={price}
            style={{ color: getStrikePriceColor(price, ethPrice, selectedToken) }}
          >
            {price}
          </option>
        ))}
      </Select>
    </Section>
  );
};
interface LeverageSelectorProps {
  leverage: number;
  setLeverage: (leverage: number) => void;
  isCustomLeverage: boolean;
  setIsCustomLeverage: (isCustom: boolean) => void;
  customLeverageInput: string;
  setCustomLeverageInput: (input: string) => void;
}

const LeverageSelector: React.FC<LeverageSelectorProps> = ({
  leverage,
  setLeverage,
  isCustomLeverage,
  setIsCustomLeverage,
  customLeverageInput,
  setCustomLeverageInput
}) => {
  const handleLeverageChange = (value: string) => {
    if (value === 'custom') {
      setIsCustomLeverage(true);
    } else {
      setIsCustomLeverage(false);
      setLeverage(parseFloat(value));
      setCustomLeverageInput('');
    }
  }
  const handleCustomLeverageChange = (value: string) => {
    setCustomLeverageInput(value);
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) && parsedValue > 0) {
      setLeverage(parsedValue);
    } else {
      setLeverage(1);
    }
  };

  return (
    <Section>
      <SectionTitle>Step 4: Select leverage</SectionTitle>
      <Select
        value={isCustomLeverage ? 'custom' : leverage.toString()}
        onChange={(e) => handleLeverageChange(e.target.value)}
      >
        <option value="1">1x</option>
        <option value="2">2x</option>
        <option value="5">5x</option>
        <option value="10">10x</option>
        <option value="custom">Custom</option>
      </Select>
      {isCustomLeverage && (
        <Input
          type="number"
          value={customLeverageInput}
          onChange={(e) => handleCustomLeverageChange(e.target.value)}
          placeholder="Enter custom leverage (e.g., 3.5)"
        />
      )}
    </Section>
  );
};
interface SlippageSelectorProps {
  slippage: number;
  setSlippage: (slippage: number) => void;
  isCustomSlippage: boolean;
  setIsCustomSlippage: (isCustom: boolean) => void;
  customSlippageInput: string;
  setCustomSlippageInput: (input: string) => void;
}

const SlippageSelector: React.FC<SlippageSelectorProps> = ({
  slippage,
  setSlippage,
  isCustomSlippage,
  setIsCustomSlippage,
  customSlippageInput,
  setCustomSlippageInput
}) => {
  const handleSlippageChange = (value: string) => {
    if (value === 'custom') {
      setIsCustomSlippage(true);
    } else {
      setIsCustomSlippage(false);
      setSlippage(parseFloat(value));
      setCustomSlippageInput('');
    }
  };

  const handleCustomSlippageChange = (value: string) => {
    setCustomSlippageInput(value);
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) && parsedValue > 0) {
      setSlippage(parsedValue);
    } else {
      setSlippage(1);
    }
  };

  return (
    <Section style={{ flex: 1 }}>
      <SectionTitle>Step 5: Select slippage</SectionTitle>
      <Select
        value={isCustomSlippage ? 'custom' : slippage.toString()}
        onChange={(e) => handleSlippageChange(e.target.value)}
      >
        <option value="1">1%</option>
        <option value="2">2%</option>
        <option value="5">5%</option>
        <option value="auto" disabled>Auto (unavailable)</option>
        <option value="custom">Custom</option>
      </Select>
      {isCustomSlippage && (
        <Input
          type="number"
          value={customSlippageInput}
          onChange={(e) => handleCustomSlippageChange(e.target.value)}
          placeholder="Enter custom slippage %"
        />
      )}
    </Section>
  );
};
// OrderbookInfo Component
interface OrderbookInfoProps {
  orderbook: OrderbookResponse;
  leverage: number;
  slippage: number;
}

const OrderbookInfo: React.FC<OrderbookInfoProps> = ({ orderbook, leverage, slippage }) => {
  return (
    <div>
      <SectionTitle>Orderbook:</SectionTitle>
      <p>Highest Bid: {orderbook.highestBid}</p>
      <p>Amount Available: {orderbook.amountAvailable}</p>
      <p>Base APR: {orderbook.apr}%</p>
      <p>Leveraged APR Range: {calculateAprRange(orderbook.apr, leverage, slippage)}</p>
      <p>Note: APR might decrease by up to {slippage}% during trade execution due to slippage.</p>
    </div>
  );
};

// Add a new component for the amount input
interface AmountInputProps {
  amount: string;
  setAmount: (amount: string) => void;
  selectedToken: string;
}

const AmountInputComponent: React.FC<AmountInputProps> = ({ amount, setAmount, selectedToken }) => {
  return (
    <Section>
      <SectionTitle>Enter amount to trade</SectionTitle>
      <AmountInput
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder={`Enter amount in ${selectedToken}`}
      />
    </Section>
  );
};

// Main App component
function App() {
  const [options, setOptions] = useState<ETHOptionsResponse | null>(null);
  const [selectedToken, setSelectedToken] = useState<string>('ETH');
  const [selectedMaturity, setSelectedMaturity] = useState<string>('');
  const [selectedStrikePrice, setSelectedStrikePrice] = useState<number>(0);
  const [ethPrice, setEthPrice] = useState<number | null>(null);
  const [orderbook, setOrderbook] = useState<OrderbookResponse | null>(null);
  const [leverage, setLeverage] = useState<number>(1);
  const [isCustomLeverage, setIsCustomLeverage] = useState<boolean>(false);
  const [customLeverageInput, setCustomLeverageInput] = useState<string>('');
  const [slippage, setSlippage] = useState<number>(5);
  const [isCustomSlippage, setIsCustomSlippage] = useState<boolean>(false);
  const [customSlippageInput, setCustomSlippageInput] = useState<string>('');
  const [amount, setAmount] = useState<string>('');

  useEffect(() => {
    axios.get(`${API_URL}/eth-options`)
      .then(response => {
        console.log('API Response:', response.data);
        setOptions(response.data);

        if (response.data.options) {
          const type = selectedToken === 'ETH' ? 'C' : 'P';
          if (response.data.options[type]) {
            const maturities = Object.keys(response.data.options[type]);
            if (maturities.length > 0) {
              const latestMaturity = maturities[maturities.length - 1];
              setSelectedMaturity(latestMaturity);

              const strikePrices = response.data.options[type][latestMaturity];
              if (strikePrices && strikePrices.length > 0) {
                const middleIndex = Math.floor(strikePrices.length / 2);
                setSelectedStrikePrice(strikePrices[middleIndex]);
              }
            }
          }
        }
      })
      .catch(error => {
        console.error('Error fetching options:', error);
      });

    axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd')
      .then(response => {
        setEthPrice(response.data.ethereum.usd);
      })
      .catch(error => {
        console.error('Error fetching ETH price:', error);
      });
  }, [selectedToken]);

  const fetchOrderbook = useCallback(() => {
    if (selectedToken && selectedMaturity && selectedStrikePrice) {
      const type = selectedToken === 'ETH' ? 'C' : 'P';
      const instrumentName = `ETH-${selectedMaturity}-${selectedStrikePrice}-${type}`;

      axios.post(`${API_URL}/orderbook`, { instrumentName })
        .then(response => {
          setOrderbook(response.data);
        })
        .catch(error => {
          console.error('Error fetching orderbook:', error);
        });
    }
  }, [selectedToken, selectedMaturity, selectedStrikePrice]);

  useEffect(() => {
    fetchOrderbook();
  }, [fetchOrderbook]);

  if (!options) {
    return <AppContainer>Loading...</AppContainer>;
  }

  const type = selectedToken === 'ETH' ? 'C' : 'P';
  const maturities = options.options && options.options[type]
    ? Object.keys(options.options[type])
    : [];
  const strikePrices = options.options && options.options[type] && selectedMaturity
    ? options.options[type][selectedMaturity]
    : [];

  return (
    <AppContainer>
      <Header>
        <h1>0 Options</h1>
        <p>Options Liquidity API and tools</p>
      </Header>
      <FlexContainer>
        <TokenSelector
          selectedToken={selectedToken}
          setSelectedToken={setSelectedToken}
          options={options}
        />
        <MaturitySelector
          selectedMaturity={selectedMaturity}
          setSelectedMaturity={setSelectedMaturity}
          maturities={maturities}
        />
      </FlexContainer>

      <FlexContainer>
        <StrikePriceSelector
          selectedStrikePrice={selectedStrikePrice}
          setSelectedStrikePrice={setSelectedStrikePrice}
          strikePrices={strikePrices}
          ethPrice={ethPrice}
          selectedToken={selectedToken}
        />
        <LeverageSelector
          leverage={leverage}
          setLeverage={setLeverage}
          isCustomLeverage={isCustomLeverage}
          setIsCustomLeverage={setIsCustomLeverage}
          customLeverageInput={customLeverageInput}
          setCustomLeverageInput={setCustomLeverageInput}
        />
      </FlexContainer>
      <FlexContainer>
        <SlippageSelector
          slippage={slippage}
          setSlippage={setSlippage}
          isCustomSlippage={isCustomSlippage}
          setIsCustomSlippage={setIsCustomSlippage}
          customSlippageInput={customSlippageInput}
          setCustomSlippageInput={setCustomSlippageInput}
        />
        <SelectedInstrument>
          <SectionTitle>Selected instrument:</SectionTitle>
          ETH-{selectedMaturity}-
          <span style={{ color: getStrikePriceColor(selectedStrikePrice, ethPrice, selectedToken) }}>
            {selectedStrikePrice}
          </span>
          -{type}
        </SelectedInstrument>
      </FlexContainer>

      {orderbook && (
        <>
        <OrderbookInfo
          orderbook={orderbook}
          leverage={leverage}
          slippage={slippage}
        />
        <AmountInputComponent
            amount={amount}
            setAmount={setAmount}
            selectedToken={selectedToken}
          />
        </>
      )}
    </AppContainer>
  );
};

export default App;



